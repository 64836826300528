import React, { useState } from 'react';
// import PaginationDiv from '../CommonPages/components/PaginationDiv';
// import AchievementCard from './AchievementCard';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import Publication from './Publication';
import "../../assets/css/tab.css"
import SectionGridLines from '../CommonPages/SectionGridLines';


const AchievementContainer = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [show2024A, setShow2024A] = useState(false);
    const [show2024B, setShow2024B] = useState(false);
    const [show2024C, setShow2024C] = useState(false);
    const [show2023, setShow2023] = useState(false);
    const [show2022, setShow2022] = useState(false);
    const [show2021A, setShow2021A] = useState(false);
    const [show2021B, setShow2021B] = useState(false);
    const [show2020, setShow2020] = useState(false);
    const [show2018, setShow2018] = useState(false);
    const [show2018B, setShow2018B] = useState(false);
    const [show2017, setShow2017] = useState(false);
    const [show2015, setShow2015] = useState(false);
    const [show2014, setShow2014] = useState(false);
    const [show2014B, setShow2014B] = useState(false);
    const [show2012A, setShow2012A] = useState(false);
    const [show2012B, setShow2012B] = useState(false);
    const [show2011A, setShow2011A] = useState(false);
    const [show2011B, setShow2011B] = useState(false);
    const [show2011C, setShow2011C] = useState(false);
    const [show2010A, setShow2010A] = useState(false);

    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);


    return (
        <section className="blog">
            <SectionGridLines />
            <div className="container">
                {/* PUBLICATION */}
                <div className="row">
                    <div className="large_font">
                        <h2 className="floating_element text-dark-200 d-flex justify-content-center">PUBLICATION</h2>
                    </div>

                    <Publication img='images/achievements/archiDiary.png' subTitle='Avenue 36' link='https://thearchitectsdiary.com/material-arrangement-welcomes-one-with-wooden-fluted-panel-shreesh-design-studio-llp/' date='2024' dateTime='2024-10-20' mainTitle='The Architects Diary' />

                    <Publication img='images/achievements/archiDiary.png' subTitle='Titan 54' link='https://thearchitectsdiary.com/a-vast-canvas-such-as-this-home-blends-various-interior-styles-shreesh-design-studio-llp/' date='2024' dateTime='2024-10-20' mainTitle='The Architects Diary' />

                    <Publication img='images/achievements/archiDiary.png' subTitle='Intricate Indian Crafted Door in 50 Timeless Pooja Room door design for Indian Homes' link='https://thearchitectsdiary.com/50-timeless-pooja-room-door-design-for-indian-homes/?fbclid=PAZXh0bgNhZW0CMTEAAabWWDKcU2YlKABIHVqrKc3D8G8dic8-_b7U2OEemQNZIlHUTiALBVu1pdM_aem_ZjxHuIzFKwXbZh3otndk4Q' date='2024' dateTime='2024-10-20' mainTitle='The Architects Diary' />

                    <Publication img='images/achievements/archiDiary.png' subTitle='Tranquil Haven' link='https://thearchitectsdiary.com/a-productive-working-space-that-resonates-with-the-firms-ethics-shreesh-design-studio-llp/' date='2024' dateTime='2024-10-20' mainTitle='The Architects Diary' />

                    <Publication img='images/achievements/archiDiary.png' subTitle='The Luxe Wave' link='https://thearchitectsdiary.com/the-luxe-house-captures-affection-an-togetherness-shreesh-design-studio-llp/' date='2024' dateTime='2024-10-20' mainTitle='The Architects Diary' />

                    <Publication img='images/achievements/dezeen.png' subTitle='Modernized Gurukul' link='https://www.dezeen.com/2024/07/15/colour-humanise-buildings-laura-guido-clark-opinion/?fbclid=PAZXh0bgNhZW0CMTEAAaZaq0cvTPNbW_UpFb5rdT_vKaBMWLZF_oHRTRCAf5CfVPEKlgzZGcPIKPo_aem_lEvYJRDpy9ShCIRqktq5vw' date='2024' dateTime='2022-10-20' mainTitle='Dezeen' />

                    <Publication img='images/achievements/aRCHHELLO.png' subTitle='Elite Chambers' link='https://archello.com/project/elite-chambers?fbclid=PAZXh0bgNhZW0CMTEAAaZZfOmIzMQILn6Cy7LpriBMgymE9l7ddTTjFy9-NkGr-fmJVkFhAbPvK-I_aem_IP4fKYKVQXzPh4_LHv01kA' date='2024' dateTime='2022-10-20' mainTitle='Archello' />

                    <Publication img='images/achievements/BMREPORTER.png' subTitle='Modernized Gurukul' link='https://www.buildingmaterialreporter.com/influencers/projects/contemporary-gurukul-school-where-learning-meets-laughter' date='2024' dateTime='2022-10-20' mainTitle='BMReporter' />

                    <Publication img='images/achievements/dezeen.png' subTitle='Tranquil Haven' link='https://www.instagram.com/p/DCWaPCLOazC/' date='2024' dateTime='2022-10-20' mainTitle='Arc Only' />



                    <Publication img='images/achievements/archiDiary.png' subTitle='Modernized Gurukul' link='https://thearchitectsdiary.com/a-modernized-gurukul-conceptualised-to-design-this-colourful-pre-school-in-gujarat-shreesh-design-studio/' date='2023' dateTime='2023-10-20' mainTitle='The Architects Diary ' />

                    <Publication img='images/achievements/archiDiary.png' subTitle='Yamuna Palace' link='https://thearchitectsdiary.com/yamuna-palace-is-an-ode-to-the-diversity-of-indian-culture-art-religion-and-heritage-shreesh-design-studio/' date='2023' dateTime='2023-10-20' mainTitle='The Architects Diary ' />

                    <Publication img='images/achievements/archiDiary.png' subTitle='Headquarters' link='https://thearchitectsdiary.com/a-productive-working-space-that-resonates-with-the-firms-ethics-shreesh-design-studio-llp/' date='2023' dateTime='2022-10-20' mainTitle='The Architects Diary ' />

                    <Publication img='images/achievements/rethinkingfuture.png' subTitle='Modernized Gurukul' link='https://www.re-thinkingthefuture.com/architectural-news/a9730-indian-preschool-built-as-colourful-as-the-minds-of-its-users-by-shreesh-design-studio/' date='2023' dateTime='2022-10-20' mainTitle='Re-thinking the Future' />

                    <Publication img='images/achievements/gaga.png' subTitle='Modernized Gurukul' link='https://www.creativegaga.com/architecture/whimsical-colours-and-playful-learning-exploring-a-vibrant-preschool' date='2023' dateTime='2022-10-20' mainTitle='Creative Gaga' />

                    <Publication img='images/achievements/dezeen.png' subTitle='Modernized Gurukul' link='https://www.dezeen.com/2023/03/17/shreesh-design-studio-colourful-preschool-india-morbi-gujarat/' date='2023' dateTime='2022-10-20' mainTitle='Dezeen' />

                    <Publication img='images/achievements/dezeen.png' subTitle='Modernized Gurukul' link='https://www.youtube.com/watch?v=BVvJKsgBMS8&t=2s&ab_channel=Dezeen' date='2023' dateTime='2022-10-20' mainTitle='Dezeen' />

                    <Publication img='images/achievements/arqa.png' subTitle='Modernized Gurukul' link='https://arqa.com/en/architecture/navyug-pre-school.html' date='2023' dateTime='2022-10-20' mainTitle='Arqa' />

                    <Publication img='images/achievements/surfacemag.png' subTitle='Modernized Gurukul' link='https://surfacemag.com/articles/palihouse-west-hollywood/' date='2023' dateTime='2022-10-20' mainTitle='Surface mag' />

                    <Publication img='images/achievements/homepublication.png' subTitle='La Pinoz, Virani CHowk' link='https://www.instagram.com/p/ClgJ8vYS3i9/' date='2023' dateTime='2022-10-20' mainTitle='Home Publication' />

                </div>

                {/* ARTICLE */}
                <div className="row" >
                    <div className="large_font">
                        <h2 className="floating_element text-dark-200 d-flex justify-content-center">ARTICLE</h2>
                    </div>
                    <Tabs
                        selectedIndex={tabIndex}
                        onSelect={tabIndex => setTabIndex(tabIndex)}
                    >
                        <TabList className="d-flex flex-wrap justify-content-between ps-0" style={{ borderBottom: '1px solid gray' }}>
                            <Tab className="yeartab">2024</Tab>
                            <Tab className="yeartab">2023</Tab>
                            <Tab className="yeartab">2022</Tab>
                            <Tab className="yeartab">2021</Tab>
                            <Tab className="yeartab">2020</Tab>
                            <Tab className="yeartab">2018</Tab>
                            <Tab className="yeartab">2017</Tab>
                            <Tab className="yeartab">2015</Tab>
                            <Tab className="yeartab">2014</Tab>
                            <Tab className="yeartab">2012</Tab>
                            <Tab className="yeartab">2011</Tab>
                            <Tab className="yeartab">2010</Tab>
                        </TabList>
                        <div style={{ minHeight: '30rem' }}>
                            <TabPanel className="position-relative">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2024A(true)}>
                                            <img src="images/achievements/articles/article-2024.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2024</h2>
                                                <h6 className='mt-2'>Elegance</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2024A} size="lg" centered onHide={() => setShow2024A(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Elegance</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/article-2024.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2024B(true)}>
                                            <img src="images/achievements/articles/article-2024-(2).jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2024</h2>
                                                <h6 className='mt-2'>Elegance</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2024B} size="lg" centered onHide={() => setShow2024B(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Elegance</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/article-2024-(2).jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2024C(true)}>
                                            <img src="images/achievements/articles/article-2024-(3).jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2024</h2>
                                                <h6 className='mt-2'>Elegance</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2024C} size="lg" centered onHide={() => setShow2024C(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Elegance</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/article-2024-(3).jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel className="position-relative">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2023(true)}>
                                            <img src="images/achievements/articles/2023-c.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2023</h2>
                                                <h6 className='mt-2'>FOAID</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2023} size="lg" centered onHide={() => setShow2023(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>FOAID</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/FOAID.jpeg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel className="position-relative">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2022(true)}>
                                            <img src="images/achievements/articles/2022-2.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2 >2022</h2>
                                                <h6 className='mt-2'>Construction & Architecture Update</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2022} size="lg" centered onHide={() => setShow2022(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Construction & Architecture Update</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/2022-1.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2021A(true)}>
                                            <img src="images/achievements/articles/2021-1c.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2 >2021</h2>
                                                <h6 className='mt-2'>Construction & Architecture Update</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2021A} size="lg" centered onHide={() => setShow2021A(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Construction & Architecture Update</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/2021-1i.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2021B(true)}>
                                            <img src="images/achievements/articles/2021-2c.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2 >2021</h2>
                                                <h6 className='mt-2'>Inside Architecture Magazine</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2021B} size="lg" centered onHide={() => setShow2021B(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Inside Architecture Magazine</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/2021-2i.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2020(true)}>
                                            <img src="images/achievements/articles/2020-c.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2 >2020</h2>
                                                <h6 className='mt-2'>Construction & Architecture Update</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2020} size="lg" centered onHide={() => setShow2020(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Construction & Architecture Update</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/2020-i.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2018(true)}>
                                            <img src="images/achievements/articles/2018-ca.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2018</h2>
                                                <h6 className='mt-2'>Construction & Architecture Update</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2018} size="lg" centered onHide={() => setShow2018(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Construction & Architecture Update</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/2018-ca.jpg" alt="" />
                                                    <img className='mt-2' src="images/achievements/articles/2018-ia.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2018B(true)}>
                                            <img src="images/achievements/articles/2018-cb.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2018</h2>
                                                <h6 className='mt-2'>Construction & Architecture Update</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2018B} size="lg" centered onHide={() => setShow2018B(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Construction & Architecture Update</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/2018-ib1.jpg" alt="" />
                                                    <img className='mt-2' src="images/achievements/articles/2018-ib2.jpg" alt="" />
                                                    <img className='mt-2' src="images/achievements/articles/2018-ib3.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2017(true)}>
                                            <img src="images/achievements/articles/2017-c.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2017</h2>
                                                <h6 className='mt-2'>Construction & Architecture Update</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2017} size="lg" centered onHide={() => setShow2017(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Construction & Architecture Update</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/2017-i.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>

                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2015(true)}>
                                            <img src="images/achievements/articles/2015-c.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2015</h2>
                                                <h6 className='mt-2'>Construction & Architecture Update</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2015} size="lg" centered onHide={() => setShow2015(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Construction & Architecture Update</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/2015-i1.jpg" alt="" />
                                                    <img src="images/achievements/articles/2015-i2.jpg" alt="" />
                                                    <img src="images/achievements/articles/2015-i3.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>

                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2014(true)}>
                                            <img src="images/achievements/articles/2014.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2014</h2>
                                                <h6 className='mt-2'>Akila</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2014} size="lg" centered onHide={() => setShow2014(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Akila</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/2014.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2014B(true)}>
                                            <img src="images/achievements/articles/2014-c.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2014</h2>
                                                <h6 className='mt-2'>Rajkot Now</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2014B} size="lg" centered onHide={() => setShow2014B(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Rajkot Now</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <center>
                                                        <img src="images/achievements/articles/2014-b.jpg" alt="" />
                                                        <img src="images/achievements/articles/2014-b2.jpg" alt="" />
                                                    </center>
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>

                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2012A(true)}>
                                            <img src="images/achievements/articles/2012-c.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2012</h2>
                                                <h6 className='mt-2'>Construction & Architecture Update</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2012A} size="lg" centered onHide={() => setShow2012A(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Construction & Architecture Update</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/2012-i1A.jpg" alt="" />
                                                    <img className='mt-2' src="images/achievements/articles/2012-i2A.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2012B(true)}>
                                            <img src="images/achievements/articles/2012-c2.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2012</h2>
                                                <h6 className='mt-2'>Rajkotwala</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2012B} size="lg" centered onHide={() => setShow2012B(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Rajkotwala</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <center>
                                                        <img src="images/achievements/articles/2012-i1b.jpg" alt="" />
                                                        <img src="images/achievements/articles/2012-i2b.jpg" alt="" />
                                                    </center>
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>

                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2011A(true)}>
                                            <img src="images/achievements/articles/2011-a.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2011</h2>
                                                <h6 className='mt-2'>Construction & Architecture Update <br />(Jan-Feb)</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2011A} size="lg" centered onHide={() => setShow2011A(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Construction & Architecture Update (Jan-Feb)</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/2011-a.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2011B(true)}>
                                            <img src="images/achievements/articles/2011-b.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2011</h2>
                                                <h6 className='mt-2'>Construction & Architecture Update <br /> (Jul-Aug)</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2011B} size="lg" centered onHide={() => setShow2011B(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Construction & Architecture Update(Jul-Aug)</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/2011-b.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2011C(true)}>
                                            <img src="images/achievements/articles/2011-c3.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2011</h2>
                                                <h6 className='mt-2'>Construction & Architecture Update <br /> (Jul-Aug)</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2011C} size="lg" centered onHide={() => setShow2011C(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Construction & Architecture Update(Jul-Aug)</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <center>
                                                        <img src="images/achievements/articles/2011-i1c.jpg" alt="" />
                                                        <img className='mt-2' src="images/achievements/articles/2011-i2c.jpg" alt="" />
                                                    </center>
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>


                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mt-2 articals" onClick={() => setShow2010A(true)}>
                                            <img src="images/achievements/articles/2010-c1.jpg" alt="" />
                                            <div className='artical-details'>
                                                <h2>2010</h2>
                                                <h6 className='mt-2'>Construction & Architecture Update <br />(Jan-Feb)</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <Modal show={show2010A} size="lg" centered onHide={() => setShow2010A(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Construction & Architecture Update (Jan-Feb)</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src="images/achievements/articles/2010-ia1.jpg" alt="" />
                                                    <img className='mt-2' src="images/achievements/articles/2010-ia2.jpg" alt="" />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </Tabs>
                </div>

                {/* AWARDS */}
                <div className="row justify-content-center">
                    <div className="large_font">
                        <h2 className="floating_element text-dark-200 d-flex justify-content-center">AWARDS</h2>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-2">
                        <div className="mt-2 articals" onClick={() => setShow4(true)}>
                            <img src="images/achievements/awards/2018-1.jpg" alt="" style={{ maxHeight: '31rem', width: '100%' }} />
                            <div className='artical-details'>
                                <h2 >2018</h2>
                                <h6 className='mt-2'>The Luminaries by Divya Bhaskar</h6>
                            </div>
                        </div>
                        <div>
                            <Modal show={show4} size="lg" centered onHide={() => setShow4(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>The Luminaries by Divya Bhaskar</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <img src="images/achievements/awards/2018-1.jpg" alt="" />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className='mb-2'>
                            <div className="mt-2 articals" onClick={() => setShow3(true)}>
                                <img src="images/achievements/awards/2016-1.jpg" alt="" style={{ maxHeight: '18rem', width: '100%' }} />
                                <div className='artical-details'>
                                    <h2 >2016</h2>
                                    <h6 className='mt-2 text-center'>Times Cyber Media</h6>
                                </div>
                            </div>
                            <div>
                                <Modal show={show3} size="lg" centered onHide={() => setShow3(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Times Cyber Media</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <img src="images/achievements/awards/2016-1.jpg" alt="" />
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                        <div className='mb-2 '>
                            <div className="mt-2 articals" onClick={() => setShow2(true)}>
                                <img src="images/achievements/awards/2021-1.jpg" alt="" style={{ maxHeight: '18rem', width: '100%' }} />
                                <div className='artical-details'>
                                    <h2 >2021</h2>
                                    <h6 className='mt-2 text-center'>Realicon Conclave & Awards by Divyabhaskar</h6>
                                </div>
                            </div>
                            <div>
                                <Modal show={show2} size="lg" centered onHide={() => setShow2(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Realicon Conclave & Awards by Divyabhaskar</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <img src="images/achievements/awards/2021-1.jpg" alt="" />
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className='mb-2 '>
                            <div className="mt-2 articals" onClick={() => setShow1(true)}>
                                <img src="images/achievements/awards/2023-1.jpg" alt="" style={{ maxHeight: '18rem', width: '100%' }} />
                                <div className='artical-details'>
                                    <h2 >2023</h2>
                                    <h6 className='mt-2'>Property Expo & Showcase</h6>
                                </div>
                            </div>
                            <div>
                                <Modal show={show1} size="lg" centered onHide={() => setShow1(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Property Expo & Showcase</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <img src="images/achievements/awards/2023-1.jpg" alt="" />
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                        <div className='mb-2 '>
                        <div className="mt-2 articals" onClick={() => setShow5(true)}>
                                <img src="images/achievements/awards/2024.jpg" alt="" style={{ maxHeight: '18rem', width: '100%' }} />
                                <div className='artical-details'>
                                    <h2 >2024</h2>
                                    <h6 className='mt-2'>ERA Fame</h6>
                                </div>
                            </div>
                            <div>
                                <Modal show={show5} size="lg" centered onHide={() => setShow5(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>ERA Fame</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <img src="images/achievements/awards/2024.jpg" alt="" />
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>




                    {/* <AchievementCard img='images/blog/6.webp' title='Recent trends in designing space interiors 2019' text='Lorem ipsum dolor sit amet consecteur adipiscing elitsed do eiusmod tempor incididunt labo dolore magnaaliqua. Ut eni ad minim veniam dolor sit amet consecteur.....' date='OCTOBER 20, 2022' dateTime='2022-10-20' postCount='06' />  */}
                </div>
                {/* <PaginationDiv /> */}
            </div>
        </section>
    );
};

export default AchievementContainer;