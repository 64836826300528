import React from 'react';
import SectionGridLines from '../CommonPages/SectionGridLines';
import { Link } from 'react-router-dom';

const AboutContainer = () => {

    const aboutdata = [
        {
            id: '1',
            img: 'images/about/i2.svg',
            text: '3D Space Designing'
        },
        {
            id: '2',
            img: 'images/about/i3.svg',
            text: 'Architectural Drawing'
        },
        {
            id: '3',
            img: 'images/about/i1.svg',
            text: '3D Model building'
        },
        {
            id: '4',
            img: 'images/about/i4.svg',
            text: 'Building The Space'
        },
    ]

    return (
        <section className="about bg-dark-100">
            {/* <SectionGridLines /> */}
            <div className="large_font">
                <h2 className="floating_element text-dark-200 d-flex justify-content-center">About</h2>
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6">
                        <div className="position-relative">
                            <img src="images/about/about.jpg" alt="img" style={{ width: '100%' }} />
                            {/* <div className='position-absolute bottom-0 right-0' style={{maxWidth:'8rem'}}>
                                <a href='/' ><img src="/images/25-years-with-bg.jpg" alt="logo" style={{ borderRadius:'20% 0'}}/></a>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 mt-2">
                        <div className="ms-lg-4 p-2">
                            <div className="about_text">
                                <h3 className="text-white text-uppercase mb-4">SHAPING UP YOUR DESIRES TO THE NEXT LEVEL</h3>
                                <p>Our works are our most priced possessions. Over the years, the language of Shreesh has modified with which the design has gracefully evolved.Steady change is a continuum at Shreesh, adapting to the rapid phases and cycles the world sees. These assorted dignitaries are our best kept word and a seal of promise for the future! </p>
                            </div>

                            {/* <div className="about_icon_box">
                                <div className="row">
                                    {
                                        aboutdata.map(data =>
                                            <div className="col-lg-6">
                                                <div className="icon_box_flex">
                                                    <div className="icon_size">
                                                        <img src={data.img} alt="Icon" />
                                                    </div>
                                                    <h6>{data.text}</h6>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div> */}

                            <div className="btn_group" >
                                <Link to="/about" className="btn gray">Read More</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutContainer;