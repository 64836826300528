import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
import MyLoader from '../../Shared/MyLoader';

const EliteChambers = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
    return (
        <div>
            <main className="wrapper">
                <SectionGridLines />
                <Outlet />
                <PageBanner pageTitle='Projects' subpath='/projects' title="Elite Chambers" activePage="Elite Chambers" />
                <section className="project-details bg-dark-200">
                    <div className="container">
                        <div className="gallery_slider">
                            <div className="swiper swiper_gallery">
                                <Swiper
                                    modules={[EffectFade, Navigation, Autoplay]}
                                    effect="slide"
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    navigation={{
                                        prevEl: '.swiper-button-prev',
                                        nextEl: '.swiper-button-next',
                                    }}
                                >
                                    
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Commercial/EliteChambers/eliteChambers1.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Commercial/EliteChambers/eliteChambers2.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Commercial/EliteChambers/eliteChambers3.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Commercial/EliteChambers/eliteChambers4.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Commercial/EliteChambers/eliteChambers5.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Commercial/EliteChambers/eliteChambers6.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Commercial/EliteChambers/eliteChambers7.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Commercial/EliteChambers/eliteChambers8.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Commercial/EliteChambers/eliteChambers9.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Commercial/EliteChambers/eliteChambers10.webp' />
                                    </SwiperSlide>
                                </Swiper>

                                {/* <!-- Add Buttons --> */}
                                <div className="swiper-navigation">
                                    <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                    <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4">

                                <div className="sidebar">
                                    <div className="project-information">
                                        <h6 className="widget-title">
                                            Project Information
                                            <span className="title-line"></span>
                                        </h6>
                                        <ul>
                                            <li>
                                                <span className="data">Project :</span> <span className="value">Elite Chambers</span>
                                            </li>
                                            <li>
                                                <span className="data"> Area :</span> <span className="value">2400 Sqft</span>
                                            </li>
                                            <li>
                                                <span className="data">Location :</span> <span className="value">Space Odyssey, Rajkot,Gujarat</span>
                                            </li>
                                            <li>
                                                <span className="data">Photography :</span> <span className="value">Bhavik Shah </span>
                                            </li>
                                            <li>
                                                <span className="data">Text :</span> <span className="value">Nishal Sevak</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8">

                                <div className="project-details_inner">
                                    <div className="post_content">
                                        <div className="post-header">
                                            <h3 className="post-title">Elite Chambers</h3>
                                        </div>
                                        <div className="fulltext">
                                            <p>
                                                In an era where workspaces are evolving into vibrant centres of innovation and collaboration, Elite Chambers is birthed to bring a tide of freshness into workplace design. To feel inspired and ignite the flame of passion for work environments, Shreesh Design Studio has designed this headquarters where the greys and whites spark harmony. Doused in the reins of monochrome, this office space draws inspiration from something extraordinary. For a profession that deals with the intrinsic nature of numbers and logistics, Shreesh Design Studio has undoubtedly put together a space that fosters growth and a rooted involvement. Located in a commercial setting of Rajkot, Gujarat, the 2400 sq. ft. space is a confluence of technicality and creativity, it being the headquarters of chartered accountancy and law for six professionals. A palette that shows the depth and ingenuity of the materials paired with a light that is cast on it. The monochromatic selection gives away an undertone of rare optimism and a type of peaceful quietness. With sophistication as the guiding principle, Elite Chambers proves that substantial change in design can upgrade a work environment to a work haven.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default EliteChambers
