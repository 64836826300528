import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';

const TranquilHaven = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
    return (
        <div>

            <div>
                <main className="wrapper">
                    <SectionGridLines />
                    <Outlet />
                    <PageBanner pageTitle='Projects' subpath='/projects' title="Tranquil Haven" activePage="Tranquil Haven" />
                    <section className="project-details bg-dark-200">
                        <div className="container">
                            <div className="gallery_slider">
                                <div className="swiper swiper_gallery">
                                    <Swiper
                                        modules={[EffectFade, Navigation, Autoplay]}
                                        effect="slide"
                                        slidesPerView={1}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        loop={true}
                                        navigation={{
                                            prevEl: '.swiper-button-prev',
                                            nextEl: '.swiper-button-next',
                                        }}
                                    >
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/TranquilHaven/tranquilHaven1.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/TranquilHaven/tranquilHaven2.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/TranquilHaven/tranquilHaven3.jpg' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/TranquilHaven/tranquilHaven4.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/TranquilHaven/tranquilHaven5.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/TranquilHaven/tranquilHaven7.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/TranquilHaven/tranquilHaven8.jpg' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/TranquilHaven/tranquilHaven9.jpg' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/TranquilHaven/tranquilHaven10.jpg' />
                                        </SwiperSlide>

                                    </Swiper>

                                    {/* <!-- Add Buttons --> */}
                                    <div className="swiper-navigation">
                                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <div className="sidebar">
                                        <div className="project-information">
                                            <h6 className="widget-title">
                                                Project Information
                                                <span className="title-line"></span>
                                            </h6>
                                            <ul>
                                                <li>
                                                    <span className="data">Project:</span> <span className="value">Farm house</span>
                                                </li>
                                                <li>
                                                    <span className="data">Location:</span> <span className="value">Vadodara, Gujarat</span>
                                                </li>
                                                <li>
                                                    <span className="data">Plot area:</span> <span className="value">4875 square ft</span>
                                                </li>
                                                <li>
                                                    <span className="data">Built Up:</span> <span className="value">5507 square ft</span>
                                                </li>
                                                <li>
                                                    <span className="data">Designers:</span> <span className="value">Haresh Parsana, Dishit Parsana, Harsh Parmar, Achal Prajapati & Sheon John</span>
                                                </li>
                                                <li>
                                                    <span className="data">Client:</span> <span className="value">Hareshbhai Vora</span>
                                                </li>
                                                <li>
                                                    <span className="data">Photography:</span> <span className="value">Smit Mehta</span>
                                                </li>
                                                <li>
                                                    <span className="data">Text by:</span> <span className="value">Nishal Sevak</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8">
                                    <div className="project-details_inner">
                                        <div className="post_content">
                                            <div className="post-header">
                                                <h3 className="post-title">Tranquil Haven</h3>
                                            </div>
                                            <div className="fulltext">
                                                <p>
                                                    A home away from home: this farmhouse excludes from the city and lets you dwell among nature!
                                                </p>
                                                <p>Farmhouses are synonyms for relaxation, much-needed unwinding, and enjoyment. They are a means to escape from the mundane and indulge in the luxurious charm of privacy and spaciousness. While physically this structure is pinpointed in the outskirts of Vadodara, Gujarat, the feel and mood tell a different story – one that is centre with lush greenery, an inorganic and natural living. “The plot is situated among a sea of dense green. It stays true to its purpose by detaching from any disturbance and intends to hide among patches of grassland and cultivation lands,” explains the design team. Tranquil Haven has links of sensitivity attached within all its divisions. It narrates a story reminiscent of the good old days when the built and the inbuilt were intricately stitched together. Filled with nostalgia and deep gratitude towards its surroundings, the design tapestry of this farmhouse is humbling, co-existing in harmony.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}

export default TranquilHaven
