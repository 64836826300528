import { Routes, Route } from "react-router-dom";
import 'react-select2-wrapper/css/select2.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './App.css';
import ThemeSwitcher from './Pages/Shared/ThemeSwitcher';
import './assets/css/style.css';
import { Outlet } from 'react-router-dom';
import Home from "./Pages/HomePages/Home";
import Header from "./Pages/Shared/Headers/Header";
import Footer from "./Pages/Shared/Footers/Footer";
import Project from "./Pages/ProjectPages/Project";
import AboutUs from "./Pages/AboutUs/AboutUs";

import ServiceDetails from "./Pages/ServicePages/ServiceDetails";
import Contact from "./Pages/ContactUs/Contact";

import ErrorPage from "./Pages/ErrorPage";
import ThankYou from "./Pages/ThankYou";
import SectionGridLines from "./Pages/CommonPages/SectionGridLines";
import YamunaProject from "./Pages/ProjectPages/Residential-bunglow/YamunaProject";
import TheRegalRegreat from "./Pages/ProjectPages/Residential-bunglow/TheRegalRegreat";
import Sakariya from "./Pages/ProjectPages/Residential-bunglow/Sakariya";
import Jetpur from "./Pages/ProjectPages/Residential-bunglow/Jetpur";
import Billipatra from "./Pages/ProjectPages/Residential-bunglow/Billipatra";
import SkyDiamond from "./Pages/ProjectPages/Residential-Apartment/SkyDiamond";
import SilverHeight from "./Pages/ProjectPages/Residential-Apartment/SilverHeight";
import SidhdhiHeritage from "./Pages/ProjectPages/Residential-Apartment/SidhdhiHeritage";
import SidhdhiHeritage3 from "./Pages/ProjectPages/Residential-Apartment/SidhdhiHeritage3";
import Serenity from "./Pages/ProjectPages/Residential-Apartment/Serenity";
import GardenCity from "./Pages/ProjectPages/Residential-Apartment/GardenCity";
import Jasani from "./Pages/ProjectPages/Residential-Apartment/Dr.Jasani";
import Gor from "./Pages/ProjectPages/Residential-Apartment/DrGor";
import DiamondSquare from "./Pages/ProjectPages/Residential-Apartment/DiamondSquare";
import Asopalav from "./Pages/ProjectPages/Residential-Apartment/Asopalav";
import GrandHotel from "./Pages/ProjectPages/Hospitality-Projects/GrandHotel";
import OyeAnna from "./Pages/ProjectPages/Hospitality-Projects/OyeAnna";
import LapinozVrundavan from "./Pages/ProjectPages/Hospitality-Projects/LapinozVrundavan";
import LapinozViranichowk from "./Pages/ProjectPages/Hospitality-Projects/LapinozViranichowk";
import LapinozRaiyachowk from "./Pages/ProjectPages/Hospitality-Projects/LapinozRaiyachowk";
import JollyEnjoy from "./Pages/ProjectPages/Hospitality-Projects/JollyEnjoy";
import Intothewild from "./Pages/ProjectPages/Hospitality-Projects/Intothewild";
import Centroid from "./Pages/ProjectPages/Commercial/Centroid";
import Headquaters from "./Pages/ProjectPages/Commercial/Headquaters";
import InvestmentOptions from "./Pages/ProjectPages/Commercial/InvestmentOptions";
import Kpstudio from "./Pages/ProjectPages/Commercial/kpstudio";
import Gurukul from "./Pages/ProjectPages/Commercial/ModernizedGurukul";
import ShreejiSales from "./Pages/ProjectPages/Commercial/ShreejiSales";
import StarHospital from "./Pages/ProjectPages/Commercial/StarHospital";
import TheSpire from "./Pages/ProjectPages/Commercial/TheSpire";
import Ex_AngelPumpStall from "./Pages/ProjectPages/Commercial/Ex_AngelPumpStall";
import Ex_Rbastall from "./Pages/ProjectPages/Commercial/Ex_Rbastall";
import Ex_siddhigroupstall from "./Pages/ProjectPages/Commercial/Ex_siddhigroupstall";
import Ex_Silverconstall from "./Pages/ProjectPages/Commercial/Ex_Silverconstall";
import Utkarsh from "./Pages/ProjectPages/3D-Work/Utkarsh";
import Westhill from "./Pages/ProjectPages/3D-Work/Westhill";
import ToplandVilla from "./Pages/ProjectPages/3D-Work/ToplandVilla";
import ResedentialDuplex from "./Pages/ProjectPages/3D-Work/ResedentialDuplex";
import OrganicResidence from "./Pages/ProjectPages/3D-Work/OrganicThemedResidence";
import JollyEnjoy3D from "./Pages/ProjectPages/3D-Work/JollyEnjoy3D";
import Achievement from "./Pages/Achievement/Achievement";
import Services from "./Pages/ServicePages/Services";
import Miniheader from "./Pages/Shared/Headers/Miniheader";
import TranquilHaven from "./Pages/ProjectPages/Residential-bunglow/TranquilHaven";
import Avenue36 from "./Pages/ProjectPages/Residential-Apartment/Avenue36";
import Titan from "./Pages/ProjectPages/Residential-Apartment/Titan";
import Luxewave from "./Pages/ProjectPages/Residential-bunglow/Luxewave";
import EssenzaLiving from "./Pages/ProjectPages/Residential-bunglow/EssenzaLiving";
import EliteChambers from "./Pages/ProjectPages/Commercial/EliteChambers";
import ResidentialApartment from "./Pages/ProjectPages/3D-Work/ResidentialApartment";
import ResidentialBungalow from "./Pages/ProjectPages/3D-Work/ResidentialBungalow";
import FortuneHospital from "./Pages/ProjectPages/3D-Work/FortuneHospital";
import Tanzania from "./Pages/ProjectPages/3D-Work/Tanzania";


function App() {

  return (
    <div>

      <ThemeSwitcher />
      <Miniheader />
      <Header />
      <Outlet />
      {/* <SectionGridLines /> */}
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/projects" element={<Project />}></Route>
        <Route path="/about" element={<AboutUs />}></Route>

        <Route path="/service" element={<Services />}></Route>
        <Route path="/service-details" element={<ServiceDetails />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/achievement" element={<Achievement />}></Route>
        <Route path="*" element={<ErrorPage />}></Route>
        <Route path="/thank-you" element={<ThankYou />}></Route>

        <Route path="/yamuna" element={<YamunaProject />}></Route>
        <Route path="/regal" element={<TheRegalRegreat />}></Route>
        <Route path="/sakariya" element={<Sakariya />}></Route>
        <Route path="/jetpur" element={<Jetpur />}></Route>
        <Route path="/billipatra" element={<Billipatra />}></Route>
        <Route path="/skydiamond" element={<SkyDiamond />}></Route>
        <Route path="/silver" element={<SilverHeight />}></Route>
        <Route path="/sidhdhi" element={<SidhdhiHeritage />}></Route>
        <Route path="/sidhdhi3" element={<SidhdhiHeritage3 />}></Route>
        <Route path="/serenity" element={<Serenity />}></Route>
        <Route path="/garden" element={<GardenCity />}></Route>
        <Route path="/Jasani" element={<Jasani />}></Route>
        <Route path="/Gor" element={<Gor />}></Route>
        <Route path="/diamond" element={<DiamondSquare />}></Route>
        <Route path="/asopalav" element={<Asopalav />}></Route>
        <Route path="/grandhotel" element={<GrandHotel />}></Route>
        <Route path="/oyeanna" element={<OyeAnna />}></Route>
        <Route path="/lapinoz_vrundavan" element={<LapinozVrundavan />}></Route>
        <Route path="/lapinoz_viranichowk" element={<LapinozViranichowk />}></Route>
        <Route path="/lapinoz_raiyachowk" element={<LapinozRaiyachowk />}></Route>
        <Route path="/jollyenjoy" element={<JollyEnjoy />}></Route>
        <Route path="/intothewild" element={<Intothewild />}></Route>
        <Route path="/centroid" element={<Centroid />}></Route>
        <Route path="/headquaters" element={<Headquaters />}></Route>
        <Route path="/investmentoptions" element={<InvestmentOptions />}></Route>
        <Route path="/kpstudio" element={<Kpstudio />}></Route>
        <Route path="/gurukul" element={<Gurukul />}></Route>
        <Route path="/shreejisales" element={<ShreejiSales />}></Route>
        <Route path="/starhospital" element={<StarHospital />}></Route>
        <Route path="/thespire" element={<TheSpire />}></Route>
        <Route path="/angelstall" element={<Ex_AngelPumpStall />}></Route>
        <Route path="/rbastall" element={<Ex_Rbastall />}></Route>
        <Route path="/siddhigroupstall" element={<Ex_siddhigroupstall />}></Route>
        <Route path="/silverconstall" element={<Ex_Silverconstall />}></Route>
        <Route path="/utkarsh" element={<Utkarsh />}></Route>
        <Route path="/westhill" element={<Westhill />}></Route>
        <Route path="/toplandvilla" element={<ToplandVilla />}></Route>
        <Route path="/resedentialduplex" element={<ResedentialDuplex />}></Route>
        <Route path="/organicresidence" element={<OrganicResidence />}></Route>
        <Route path="/jollyenjoy3d" element={<JollyEnjoy3D />}></Route>
        <Route path="/tranquilHaven" element={<TranquilHaven />}></Route>
        <Route path="/avenue" element={<Avenue36 />}></Route>
        <Route path="/titan" element={<Titan />}></Route>
        <Route path="/luxewave" element={<Luxewave />}></Route>
        <Route path="/essenzaLiving" element={<EssenzaLiving />}></Route>
        <Route path="/elitechambers" element={<EliteChambers />}></Route>
        <Route path="/residentialApartment" element={<ResidentialApartment />}></Route>
        <Route path="/residentialBungalow" element={<ResidentialBungalow />}></Route>
        <Route path="/fortuneHospital" element={<FortuneHospital />}></Route>
        <Route path="/tanzania" element={<Tanzania />}></Route>

      </Routes>
      <Footer />


    </div>
  );
}

export default App;
