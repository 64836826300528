import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const SilverHeight = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
    return (
        <div>

            <main className="wrapper">
                <SectionGridLines />
                <Outlet />
                <PageBanner pageTitle='Projects' subpath='/projects' title="Silver Heights" activePage="Silver Heights" />
                <section className="project-details bg-dark-200">
                    <div className="container">
                        <div className="gallery_slider">
                            <div className="swiper swiper_gallery">
                                <Swiper
                                    modules={[EffectFade, Navigation, Autoplay]}
                                    effect="slide"
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    navigation={{
                                        prevEl: '.swiper-button-prev',
                                        nextEl: '.swiper-button-next',
                                    }}
                                >
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SilverHeight/01.jpg' style={{ height: '40rem' }} />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SilverHeight/02.jpg' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SilverHeight/03.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SilverHeight/04.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SilverHeight/05.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SilverHeight/06.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SilverHeight/07.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/Apartment/SilverHeight/08.webp' />
                                    </SwiperSlide>

                                </Swiper>

                                {/* <!-- Add Buttons --> */}
                                <div className="swiper-navigation">
                                    <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                    <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4">

                                <div className="sidebar">
                                    <div className="project-information">
                                        <h6 className="widget-title">
                                            Project Information
                                            <span className="title-line"></span>
                                        </h6>
                                        <ul>
                                            <li>
                                                <span className="data">Client:</span> <span className="value">  Sirishbhai Bambhrolia</span>
                                            </li>
                                            <li>
                                                <span className="data"> Area:</span> <span className="value">4200 Sqft</span>
                                            </li>
                                            <li>
                                                <span className="data">Location:</span> <span className="value"> Silver Height, Rajkot</span>
                                            </li>
                                            <li>
                                                <span className="data">Text:</span> <span className="value">Jhanvi Patel</span>
                                            </li>


                                        </ul>


                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8">

                                <div className="project-details_inner">
                                    <div className="post_content">
                                        <div className="post-header">
                                            <h3 className="post-title">Silver Heights</h3>
                                        </div>
                                        <div className="fulltext">
                                            <p>
                                                Each person has gone through different experience so has this modest house, with each space creating a unique acquaintance to the user. The elegant palette of wood and chalky brown unites the living room and kitchen, with indirect pops of warm lights. The accent wall in the living room has a distinctive play of shelves connecting to form TV unit with each niche highlighted up through a spotlight. Moreover, the living room is connected to an informal space to relax and a cozy corner for get together. The main attraction to the area is the green wall followed by mirrors integrated in it, which just takes over all the focus. When looked at the rooms, each space has been customized with the user’s choice of color palette and its function. Therefore, accumulating all with a classy and sassy appearance throughout.
                                            </p>



                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </div>
    )
}

export default SilverHeight
