import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';
// import Loader from '../../Shared/Loader';
// import RelatedProjects from './RelatedProjects';

const Jetpur = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
    return (
        <div>

            <div>
                <main className="wrapper">
                    <SectionGridLines />
                    <Outlet />
                    <PageBanner pageTitle='Projects' subpath='/projects' title="Jetpur Residence" activePage="Jetpur Residence" />
                    <section className="project-details bg-dark-200">
                        <div className="container">
                            <div className="gallery_slider">
                                <div className="swiper swiper_gallery">
                                    <Swiper
                                        modules={[EffectFade, Navigation, Autoplay]}
                                        effect="slide"
                                        slidesPerView={1}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        loop={true}
                                        navigation={{
                                            prevEl: '.swiper-button-prev',
                                            nextEl: '.swiper-button-next',
                                        }}
                                    >
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/JetpurResidence/01.webp' style={{ height: '40rem' }} />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/JetpurResidence/02.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/JetpurResidence/03.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/JetpurResidence/04.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/JetpurResidence/05.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/JetpurResidence/06.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/JetpurResidence/07.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/JetpurResidence/08.webp' />
                                        </SwiperSlide>

                                    </Swiper>

                                    {/* <!-- Add Buttons --> */}
                                    <div className="swiper-navigation">
                                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <div className="sidebar">
                                        <div className="project-information">
                                            <h6 className="widget-title">
                                                Project Information
                                                <span className="title-line"></span>
                                            </h6>
                                            <ul>
                                                <li>
                                                    <span className="data">Client:</span> <span className="value">Laljibhai Patel</span>
                                                </li>
                                                <li>
                                                    <span className="data">Area:</span> <span className="value">3250 Sq Ft</span>
                                                </li>
                                                <li>
                                                    <span className="data">Location:</span> <span className="value">Jetpur, Gujarat</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8">
                                    <div className="project-details_inner">
                                        <div className="post_content">
                                            <div className="post-header">
                                                <h3 className="post-title">Laljibhai’s Residence</h3>
                                            </div>
                                            <div className="fulltext">
                                                <p>
                                                    Our vision for this 5bhk project was to embellish each space uniquely and differently from the other, such that each space provides us with its own magical realism. As much as we were fascinated by this idea, it proved to be highly challenging as we worked through. As by entering inside, each space has a story to narrate beginning with our point of focus. The entrance bears a rhythmic approach, enhanced with the play of light, welcoming the guests into the world of warmth and uniqueness. Colorful sofas and a double-heighted living are provided in ground floor. Staircase are specially designed with a combination of Rcc, marble, wooden panels and indirect lighting. Dining is also providing privacy as well as I think it is the best location to have a meal with natural light. The dining comprises of a wooden dining table with a comfortable seating. A Pooja room is a symbolic part of dignity and peace. Pooja comprises of wooden paneling with beautifully carved blocks. All the rooms had different themes and were to be accommodated by drastically different personalities. There is a use of clean lines and geometric shapes in the consoles and furniture to add aesthetic appeal to the compact spaces and provide efficient storage and functional spaces.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}

export default Jetpur
