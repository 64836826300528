import React from 'react';

const MissionContainer = () => {
    return (
        <section className="mission">
            <div className="container">
                <div className="mission_top_part">
                    <div className="section-header text-center">
                        <h6 className="text-white text-uppercase">Our MISSION</h6>
                        <p className="text-gray-600">Our organizational sprit believes that where design meets passion & love with space, good architecture happens here.</p>
                    </div>
                    <div className="has_line"></div>
                    <img src="images/about/main_about.webp" alt="" className='w-100' style={{maxHeight:'40rem'}}/>
                </div>

                <div className="mission_bottom_part">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 pe-lg-4">
                            <div className="section-header">
                                <h3 className="text-white text-uppercase border-line">Modern & Luxury Interior Designers in Rajkot</h3>
                            </div>
                        </div>
                        <div className="col-lg-8 ps-lg-4">
                            <div className="mission_content">
                                <p>Scaling the design fabric of Rajkot, Gujarat, Shreesh Design Studio LLP is one of the leading interior design studio of the city. Led by the dynamic father – son pair, Shreesh is an established design power reigning since 1999.</p>

                                <p>The firm’s ethos lies in the supreme quality and class of functional, efficient, adaptive and intuitive design. With the clientele’s interests at heart, the studio has constantly walked on the path of betterment. Their symbolic representation, a series of waves signify the very soul of the firm, the organic flow of energy, changing its course in the direction of a stronger idea.</p>

                                <p>This versatile nature is a mirror in their team building procession too, where a melange of singular belief join force to branch out in multi curational thoughts. A progressive, over-the-top thinking and philosophy has fruitfully resulted into the practice being accolated ISO 9001 – 2000 for the mindful quality management of their workspace. The team that shapes the face of Shreesh is built on truthful efforts and an attitude of driving their vision to something powerful, every day.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    );
};

export default MissionContainer;