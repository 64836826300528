import React, { useEffect, useState } from "react";
import { EffectFade, Navigation, Autoplay } from "swiper";
import PageBanner from "../../CommonPages/PageBanner";
import { Swiper, SwiperSlide } from "swiper/react";
import SectionGridLines from "../../CommonPages/SectionGridLines";
import { Outlet } from "react-router-dom";
import ProjectDetailsSlider from "../ProjectDetailsSlider";
import MyLoader from "../../Shared/MyLoader";

const FortuneHospital = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />;
    }
    return (
        <div>
            <main className="wrapper">
                <SectionGridLines />
                <Outlet />
                <PageBanner pageTitle='Projects' subpath='/projects' title="Fortune Hospital" activePage="Fortune Hospital" />
                <section className="project-details bg-dark-200">
                    <div className="container">
                        <div className="gallery_slider">
                            <div className="swiper swiper_gallery">
                                <Swiper
                                    modules={[EffectFade, Navigation, Autoplay]}
                                    effect="slide"
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    navigation={{
                                        prevEl: '.swiper-button-prev',
                                        nextEl: '.swiper-button-next',
                                    }}
                                >

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/FortuneHospital/fortuneHospital1.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/FortuneHospital/fortuneHospital2.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/FortuneHospital/fortuneHospital3.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/FortuneHospital/fortuneHospital4.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/FortuneHospital/fortuneHospital5.webp' />
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/FortuneHospital/fortuneHospital6.webp' />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ProjectDetailsSlider img='images/portfolio/details/3D-Work/FortuneHospital/fortuneHospital7.webp' />
                                    </SwiperSlide>
                                    

                                </Swiper>

                                {/* <!-- Add Buttons --> */}
                                <div className="swiper-navigation">
                                    <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                    <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4">

                                <div className="sidebar">
                                    <div className="project-information">
                                        <h6 className="widget-title">
                                            Project Information
                                            <span className="title-line"></span>
                                        </h6>
                                        <ul>
                                            <li>
                                                <span className="data">Client :</span> <span className="value">Dr. Jaisukhbhai Kalathia</span>
                                            </li>
                                            <li>
                                                <span className="data">Site :</span> <span className="value">Fortune Hospital</span>
                                            </li>
                                            <li>
                                                <span className="data">Built up Area :</span> <span className="value">90,000 Sqft</span>
                                            </li>
                                            <li>
                                                <span className="data">Location :</span> <span className="value">Botad, Gujarat</span>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8">

                                <div className="project-details_inner">
                                    <div className="post_content">
                                        <div className="post-header">
                                            <h3 className="post-title">Fortune Hospital</h3>
                                        </div>
                                        <div className="fulltext">
                                            <p>
                                            A state-of-the-art hospital project spanning six floors, with all four elevations open to creative possibilities. This unique design allows for the use of diverse materials on each side, resulting in a dynamic and visually striking structure. The interplay of materials enhances both the building's functionality and aesthetic appeal, ensuring a modern and welcoming environment.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default FortuneHospital
