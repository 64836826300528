import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Miniheader from './Miniheader';


const UpperHeader = () => {
    // Hide header on scroll down
    window.onscroll = function () { myFunction() }; function myFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.querySelector('header .container').classList.add('.top-up');
        } else {
            document.querySelector('header .container').classList.remove('.top-up');
        }
    }

    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };

    useEffect(() => {
        // Aside info bar
        $('.aside_open').click(function (e) {
            e.preventDefault();
            $('.aside_info_wrapper').addClass('show');
        });
        $('.aside_close').click(function (e) {
            e.preventDefault();
            $('.aside_info_wrapper').removeClass('show');
        });

        // Toggle Header Search
        $('.header_search .form-control-submit').click(function (e) {
            $('.open_search').toggleClass('active');
        });

        // Sticky Header
        var header = $("header");
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();

            if (scroll >= 50) {
                header.addClass("bg-dark sticky");
                // Miniheader.addClass("minihead");

            } else {
                header.removeClass("bg-dark sticky");
            }
        });
    }, []);

    return (
        <>

            <div>
                <header className="header">
                    {/* <Miniheader/> */}
                    <div className="container">
                        <div>
                            <div className="header_inner d-flex align-items-center justify-content-between">
                                <div className='d-flex justify-content-center align-items-center gap-3'>
                                    {/* <div className="experience-logo">
                                        <a href='/' ><img src="/images/25-years(3).png" alt="logo" /></a>
                                    </div> */}
                                    <div className="logo">
                                        <a href='/' className="light_logo"><img src="/images/shreesh-logo.png" style={{ filter: 'invert(100%)', WebkitFilter: 'invert(100%)' }} alt="logo" /></a>
                                        <a href='/' className="dark_logo"><img src="/images/shreesh-logo.png" alt="logo" /></a>
                                    </div>
                                </div>

                                <div className='mainnav d-none d-lg-block'>
                                    <ul className='mainmenu '>
                                        <li className="menu-item"><NavLink to="/">Home</NavLink></li>
                                        <li className="menu-item"><NavLink to="projects">Projects</NavLink></li>
                                        <li className="menu-item"><NavLink to="about">About Us</NavLink></li>
                                        <li className="menu-item"><NavLink to="service">Services</NavLink></li>
                                        <li className="menu-item"><NavLink to="achievement">achievement</NavLink></li>
                                        <li className="menu-item"><NavLink to="contact">Contact Us</NavLink></li>
                                    </ul>
                                </div>
                                <div className="header_right_part d-flex align-items-center">
                                    <button className="aside_open">
                                        <span className="line"></span>
                                        <span className="line"></span>
                                        <span className="line"></span>
                                    </button>

                                    {/* Mobile Responsive Menu Toggle Button */}
                                    <button type="button" className="mr_menu_toggle d-lg-none">
                                        <i className="bi bi-list"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </>
    );
};

export default UpperHeader;