import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';

const Avenue36 = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
    return (
        <div>

            <div>
                <main className="wrapper">
                    <SectionGridLines />
                    <Outlet />
                    <PageBanner pageTitle='Projects' subpath='/projects' title="Avenue 36" activePage="Avenue 36" />
                    <section className="project-details bg-dark-200">
                        <div className="container">
                            <div className="gallery_slider">
                                <div className="swiper swiper_gallery">
                                    <Swiper
                                        modules={[EffectFade, Navigation, Autoplay]}
                                        effect="slide"
                                        slidesPerView={1}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        loop={true}
                                        navigation={{
                                            prevEl: '.swiper-button-prev',
                                            nextEl: '.swiper-button-next',
                                        }}
                                    >
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Avenue36/avenue1.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Avenue36/avenue2.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Avenue36/avenue3.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Avenue36/avenue4.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Avenue36/avenue5.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Avenue36/avenue6.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Avenue36/avenue7.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/Apartment/Avenue36/avenue8.webp' />
                                        </SwiperSlide>

                                    </Swiper>

                                    {/* <!-- Add Buttons --> */}
                                    <div className="swiper-navigation">
                                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <div className="sidebar">
                                        <div className="project-information">
                                            <h6 className="widget-title">
                                                Project Information
                                                <span className="title-line"></span>
                                            </h6>
                                            <ul>
                                                <li>
                                                    <span className="data">Client:</span> <span className="value">Dineshbhai Savaliya</span>
                                                </li>
                                                <li>
                                                    <span className="data">Location:</span> <span className="value">Avenue 36, Rajkot</span>
                                                </li>
                                                <li>
                                                    <span className="data">Carpet Area:</span> <span className="value">2,550 Sqft</span>
                                                </li>
                                                <li>
                                                    <span className="data">Photography by:</span> <span className="value">MKG studio</span>
                                                </li>
                                                <li>
                                                    <span className="data">MKG studio:</span> <span className="value">Nishal Sevak</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8">
                                    <div className="project-details_inner">
                                        <div className="post_content">
                                            <div className="post-header">
                                                <h3 className="post-title">Avenue 36</h3>
                                            </div>
                                            <div className="fulltext">
                                                <p>
                                                    Residential designs have an impending effect on not only the inhabitants but the designers as well. 36 Avenue residence by Shreesh Design Studio LLP seems to be a testament to that. Ways of functional, pleasant and efficient design are thoughtfully explored here. The entrance to this apartment itself is thrilling. An organized arrangement of materials welcomes one with wooden fluting paired with printed tiles and patches of mirror with it that seems to have its own musical. 36 Avenue residence experiments with the ordinary to deliver spaces that are going to last for a last time in a physical wonderment.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}

export default Avenue36
