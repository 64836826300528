import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';

const Luxewave = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
    return (
        <div>

            <div>
                <main className="wrapper">
                    <SectionGridLines />
                    <Outlet />
                    <PageBanner pageTitle='Projects' subpath='/projects' title="The luxe Wave" activePage="The luxe Wave" />
                    <section className="project-details bg-dark-200">
                        <div className="container">
                            <div className="gallery_slider">
                                <div className="swiper swiper_gallery">
                                    <Swiper
                                        modules={[EffectFade, Navigation, Autoplay]}
                                        effect="slide"
                                        slidesPerView={1}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        loop={true}
                                        navigation={{
                                            prevEl: '.swiper-button-prev',
                                            nextEl: '.swiper-button-next',
                                        }}
                                    >
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/LuxeWave/luxewave8.jpg' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/LuxeWave/luxewave9.jpg' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/LuxeWave/luxewave1.jpg' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/LuxeWave/luxewave2.jpg' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/LuxeWave/luxewave3.jpg' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/LuxeWave/luxewave4.jpg' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/LuxeWave/luxewave5.jpg' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/LuxeWave/luxewave7.jpg' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/LuxeWave/luxewave10.jpg' />
                                        </SwiperSlide>

                                    </Swiper>

                                    {/* <!-- Add Buttons --> */}
                                    <div className="swiper-navigation">
                                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <div className="sidebar">
                                        <div className="project-information">
                                            <h6 className="widget-title">
                                                Project Information
                                                <span className="title-line"></span>
                                            </h6>
                                            <ul>
                                                <li>
                                                    <span className="data">Client :</span> <span className="value">Hardikbhai Khunt</span>
                                                </li>
                                                <li>
                                                    <span className="data">Plot area :</span> <span className="value">2024 sqft</span>
                                                </li>
                                                <li>
                                                    <span className="data">Carpet area : <br></br> (in sq. ft.)</span> <span className="value">5,564 sqft total (Ground floor + 4 floors)</span>
                                                </li>
                                                <li>
                                                    <span className="data">Location :</span> <span className="value">Rajkot, Gujarat</span>
                                                </li>
                                                <li>
                                                    <span className="data">Photography by :</span> <span className="value">Bhavik Shah</span>
                                                </li>
                                                <li>
                                                    <span className="data">Words by :</span> <span className="value">Nishal Sevak</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8">
                                    <div className="project-details_inner">
                                        <div className="post_content">
                                            <div className="post-header">
                                                <h3 className="post-title">The luxe Wave</h3>
                                            </div>
                                            <div className="fulltext">

                                                <p>The feeling of togetherness is one to be cherished, nurtured and celebrated. Supporting this sentiment in the best way they know how Shreesh Design Studio LLP have conceptualised this home and have executed it with brimming affection. ‘The Luxe Wave’, as so appropriately named, is a residential haven located in the warm arms of a homely neighbourhood in Rajkot, Gujarat. The coming together of familial bonding and contemporary design is signified in the milieu of the house, right from the first impression. Pieced together for a pair of brothers and their families, the structure arises in four levels with a concrete and brick skin. The inspiration for the wave came from the desire to display warmth and rich character that the family had. The organic nature of the wave signifies free flow to break free from the conventional elevation treatment of right angles. The concept of adding a personal touch is evident in every design aspect of the residence. From the carefully selected color palettes to the thoughtfully incorporated decor elements, the Luxe Wave embodies a distinct identity that is elegantly portrayed throughout. Each detail has been meticulously curated to create a space that exudes sophistication and individuality.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}

export default Luxewave
