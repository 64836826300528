import React from 'react';

const ProjectDetailsSlider = (props) => {
    return (
        <div className="swiper-slide">
            <div className="gallery-image d-flex justify-content-center align-items-center projectcardheight" >
                <img src={props.img} alt="img"  style={{width:'fit-content',height:'100%'}}/>
            </div>
        </div>
    );
};

export default ProjectDetailsSlider;