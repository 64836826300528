import React, { useEffect, useState } from 'react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import PageBanner from '../../CommonPages/PageBanner';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsSlider from '../ProjectDetailsSlider';
import MyLoader from '../../Shared/MyLoader';
import SectionGridLines from '../../CommonPages/SectionGridLines';
import { Outlet } from 'react-router-dom';

const EssenzaLiving = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    }, []);

    if (isLoading) {
        // return <Loader/>;
        return <MyLoader />
    }
    return (
        <div>

            <div>
                <main className="wrapper">
                    <SectionGridLines />
                    <Outlet />
                    <PageBanner pageTitle='Projects' subpath='/projects' title="Essenza Living" activePage="Essenza Living" />
                    <section className="project-details bg-dark-200">
                        <div className="container">
                            <div className="gallery_slider">
                                <div className="swiper swiper_gallery">
                                    <Swiper
                                        modules={[EffectFade, Navigation, Autoplay]}
                                        effect="slide"
                                        slidesPerView={1}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        loop={true}
                                        navigation={{
                                            prevEl: '.swiper-button-prev',
                                            nextEl: '.swiper-button-next',
                                        }}
                                    >
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/EssenzaLiving/essenzaLiving1.jpg' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/EssenzaLiving/essenzaLiving2.jpg' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/EssenzaLiving/essenzaLiving3.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/EssenzaLiving/essenzaLiving4.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/EssenzaLiving/essenzaLiving5.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/EssenzaLiving/essenzaLiving6.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/EssenzaLiving/essenzaLiving7.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/EssenzaLiving/essenzaLiving8.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/EssenzaLiving/essenzaLiving9.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/EssenzaLiving/essenzaLiving10.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/EssenzaLiving/essenzaLiving11.webp' />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <ProjectDetailsSlider img='images/portfolio/details/residentialBunglow/EssenzaLiving/essenzaLiving12.webp' />
                                        </SwiperSlide>

                                    </Swiper>

                                    {/* <!-- Add Buttons --> */}
                                    <div className="swiper-navigation">
                                        <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                        <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <div className="sidebar">
                                        <div className="project-information">
                                            <h6 className="widget-title">
                                                Project Information
                                                <span className="title-line"></span>
                                            </h6>
                                            <ul>
                                                <li>
                                                    <span className="data">Client :</span> <span className="value">Mohsinbhai</span>
                                                </li>
                                                <li>
                                                    <span className="data">Location :</span> <span className="value">Morbi, Gujarat</span>
                                                </li>
                                                <li>
                                                    <span className="data">Photography by :</span> <span className="value">Rohan Patel</span>
                                                </li>
                                                <li>
                                                    <span className="data">Words by :</span> <span className="value">Nishal Sevak</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-8">
                                    <div className="project-details_inner">
                                        <div className="post_content">
                                            <div className="post-header">
                                                <h3 className="post-title">Essenza Living</h3>
                                            </div>
                                            <div className="fulltext">

                                                <p>A reflection of our astute selves in the most authentic and original ways, homes are our way of expression. Such is the inspiration behind this residence in Morbi, Gujarat. The site is situated on crosswords, exposing two of its facades to the road. Covered in shades of deep brown and mustard, the elevation has an ornamental nature that is shown through light detailing, intricate jaali work, and an interplay of materials. While one side of the residence is completely shut off to maintain privacy, the other side exposed to the road is looked over by balconies with artful treatments at intervals. The volume being seated on a crossroad observes a curved angle at its junction, taking advantage of the same through a sliding door instead of a gate. The volume rises above the surrounding structures, spanning two levels. The ground floor essentially houses a parking area at the front, accessible through a ramp and steps from the main road. The rear end consists of a staircase talking to the levels above.</p>
                                                <p>The designers have also incorporated the facility of a life. Apart from the storage area, the ground floor also houses a bedroom and dressing area – acting as a spare space for guests and extended family members. The residence is grounded in its culture and represents it in modern ways to create a space to make memories with family members and have a deeply personal space to grow in. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}

export default EssenzaLiving
